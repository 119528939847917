import React, { Fragment } from "react";

import Layout from "../../components/Layout";
import SignOut from "../../components/SignOut";

const SignOutPage = () => (
  <Fragment>
    <h1>Signing out...</h1>
    <SignOut />
  </Fragment>
);

const SignOutPageFinal = () => (
  <Layout>
    <SignOutPage />
  </Layout>
);

export default SignOutPageFinal;
