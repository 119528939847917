import React from "react";
import { navigate } from "gatsby";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

function SignOut({ firebase }) {
  React.useEffect(() => {
    if (firebase) {
      firebase.doSignOut().then(() => navigate(ROUTES.SIGN_IN));
    }
  }, [firebase]);

  return null;
}

export default withFirebase(SignOut);
